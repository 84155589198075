/* 
Welcome to the bologense code
*/

import Swup from "swup";
import SwupA11yPlugin from "@swup/a11y-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";
//import SwupScriptsPlugin from '@swup/scripts-plugin';
//import SwupScrollPlugin from '@swup/scroll-plugin';
//import Lenis from 'lenis'
import "@dotlottie/player-component";
//import { DotLottie } from '@lottiefiles/dotlottie-web'; // Canvas element instead of <dotlottie-player> break the animation on the website? Works on Lottie website tho??
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {rainbowCursor} from "cursor-effects";

import Swiper from "swiper";
import {
	Navigation,
	Pagination,
	Autoplay,
	FreeMode,
	A11y,
	EffectFade,
} from "swiper/modules";
import "swiper/swiper-bundle.min.css";

gsap.registerPlugin(ScrollTrigger, SplitText);
const prefersReducedMotion = window.matchMedia(
	"(prefers-reduced-motion: reduce)"
).matches;

/* Plugins */

/* Scroll plugin */
/* 	let scrollPlugin = new SwupScrollPlugin({
		animateScroll: window.matchMedia('(prefers-reduced-motion: reduce)').matches ? false : {
			betweenPages: true,
			samePageWithHash: true,
			samePage: true
		},
		doScrollingRightAway: true,
		scrollFriction: 0.4,
		scrollAcceleration: 0.02,
	}); */

/* Fragment Plugin */
let fragmentPlugin = new SwupFragmentPlugin({
	rules: [
		/* From Om-oss -> Person */
		{
			from: "/:kontakt/",
			to: "/:kontakt/personal/:person/",
			containers: ["#modal"],
			name: "open-people",
		},

		/* From Person -> Om-oss */
		{
			from: "/:kontakt/personal/:person/",
			to: "/:kontakt/",
			containers: ["#modal"],
			name: "close-people",
		},

		/* From Any Page -> Childpage */
		{
			from: "/:page/",
			to: "/:page/:child_page/",
			containers: ["#modal"],
		},

		/* From Childpage -> Any Page */
		{
			from: "/:page/:child_page/",
			to: "/:page/",
			containers: ["#modal"],
		},

		/* From Home Page -> Childpage */
		{
			from: "/",
			to: "/:page/:child_page/",
			containers: ["#modal"],
		},

		/* From Childpage -> Home Page */
		{
			from: "/:page/:child_page/",
			to: "/",
			containers: ["#modal"],
		},
	],
});

/* Init Swup */

let swup = new Swup({
	animateHistoryBrowsing: true,
	plugins: [
		new SwupPreloadPlugin(),
		new SwupA11yPlugin(),
		fragmentPlugin,
		/* 		new SwupScriptsPlugin({
			head: false,
			body: false,
		}), */
	],
});

/* Swup Hooks */
swup.hooks.on("page:view", (visit) => {
	modalClose();
	overflowBody();

	if (visit.containers.includes("#swup")) {
		main();
	} else {
		modalAnimation();
		console.log("modal container");
	}

	let currentUrl = window.location.href;
	// Check if it's not the first visit (i.e., sessionStorage already has "pageVisited")
	if (sessionStorage.getItem("pageVisited")) {
		sessionStorage.setItem("lastPage", currentUrl); // Set the last visited page
	}
});

// Handle initial visit (when the page is loaded directly)
window.addEventListener("load", function () {
	sessionStorage.setItem("pageVisited", false);
	sessionStorage.removeItem("lastPage"); // Clear lastPage for the first page;
	console.log("sessionStorage Reset -> Load");
	modalClose();
	modalAnimation();
});

/* Dialog Close */
function modalClose() {
	let dialogs = document.querySelectorAll("div#modal");
	if (dialogs.length > 0) {
		let lastPage = sessionStorage.getItem("lastPage");
		let ultraParentUrl = document
			.querySelector("main")
			.getAttribute("data-parent-url");
		let parentUrl = dialogs[0].getAttribute("data-parent-url");

		dialogs.forEach(function (dialog) {
			/* Just to prevent spamming the buttons */
			let escUsed = false;
			let clickUsed = false;

			dialog.querySelectorAll(".close").forEach(function (button) {
				// Set the button's href based on lastPage or parentUrl
				if (lastPage !== null) {
					button.href = lastPage;
				} else if (ultraParentUrl) {
					button.href = ultraParentUrl;
				} else {
					button.href = parentUrl;
				}

				button.addEventListener("click", function (event) {
					if (!clickUsed) {
						event.preventDefault();
						clickUsed = true;
					}
				});
			});

			// Add keydown event listener for 'Escape' key
			document.addEventListener("keydown", function (event) {
				if (
					event.key === "Escape" &&
					!escUsed &&
					dialog.style.display !== "none"
				) {
					var button = document.getElementById("modalClose");
					button.click();
					escUsed = true;
				}
			});

			// Reset the escUsed and clickUsed flags when modal is manually closed
			dialog.addEventListener("click", function (event) {
				if (event.target === dialog) {
					escUsed = false;
					clickUsed = false;
				}
			});
		});
	}
}

/* Cursor-effects - Draw line */
function initCursor() {
	return new rainbowCursor({
		colors: ["#00A873"],
		length: 20,
		size: 4,
	});
}
let customCursor = initCursor();
function resetCursor() {
	if (customCursor) {
		customCursor.destroy();
	}
	customCursor = initCursor();
}

/* Custom cursor */
document.addEventListener("mousemove", function (e) {
	let mouseX = e.pageX; // + 10
	let mouseY = e.pageY - window.scrollY; // + 10
	let cursorOuter = document.querySelector(".cursorOuter");
	requestAnimationFrame(moveCursor);

	function moveCursor() {
		cursorOuter.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
	}
});

/* Stop main scroll when modal is open */
function overflowBody() {
	if (document.querySelectorAll("div#modal").length > 0) {
		document.querySelector("body").classList.add("overflow-hidden");
	} else {
		document.querySelector("body").classList.remove("overflow-hidden");
	}
}
overflowBody();

/* Preloader */
function preloader() {
	function moreThanTwelveHoursOld(timestamp) {
		const twelveHours = 1 * 60 * 60 * 1000; // milliseconds in hour
		const now = new Date().getTime(); // current time in milliseconds
		return now - timestamp > twelveHours;
	}

	document.addEventListener("DOMContentLoaded", function () {});

	const firstVisitInfo = localStorage.getItem("first_visit_info");
	const visitData = firstVisitInfo ? JSON.parse(firstVisitInfo) : null;
	const preloader_element = document.getElementById("preloader");
	const lottieCanvas = document.getElementById("pre__lottie");
	//const lottieSrc = lottieCanvas.getAttribute('data-src');

	/* 		let preloaderLottieAnimation = new DotLottie({
			canvas: lottieCanvas,
			src: lottieSrc,
			loop: false,
			autoplay: false
		}); */

	if (visitData && !moreThanTwelveHoursOld(visitData.timestamp)) {
		console.log("2nd visit within 1h");

		document.addEventListener("DOMContentLoaded", function () {
			main(); // Retrigger

			preloader_element.classList.add("fade-out");
			preloader_element.addEventListener("transitionend", function () {
				preloader_element.remove();
			});
		});

		const newVisitData = {
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("first_visit_info", JSON.stringify(newVisitData));
	} else {
		console.log("1st visit in 1h");

		document.addEventListener("DOMContentLoaded", function () {
			setTimeout(() => {
				lottieCanvas.play();
				lottieCanvas.addEventListener("complete", function () {
					main(); // Retrigger
					preloader_element.classList.add("fade-out");

					preloader_element.addEventListener("transitionend", function () {
						preloader_element.remove();
					});
				});
			}, 200);
		});

		const newVisitData = {
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("first_visit_info", JSON.stringify(newVisitData));
	}
}
preloader();

/* Skiplink */
document.querySelector(".skipLink").addEventListener("click", handleSkipLink);
document.querySelector(".skipLink").addEventListener("keydown", function (e) {
	if (e.key === "Enter" || e.key === " ") {
		handleSkipLink(e);
	}
});

function handleSkipLink(e) {
	var target = document.getElementById("swup");
	target.setAttribute("tabindex", "-1");
	target.focus();
}

function main() {
	resetCursor(); // reset cursor betwene pages
	ScrollTrigger.getAll().forEach((st) => st.kill());
	//swiper.destroy(true, true) // FIX target every slide function

	/* Load videos after swup transition*/
	function loadVideos() {
		const videos = document.querySelectorAll("video");
		videos.forEach((video) => {
			video.load();
		});
	}
	loadVideos();

	/* Lazyload auto videos */
	let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
	if ("IntersectionObserver" in window) {
		let lazyVideoObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (let source in video.target.children) {
						let videoSource = video.target.children[source];
						if (
							typeof videoSource.tagName === "string" &&
							videoSource.tagName === "SOURCE"
						) {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}

	/* Fixes loading=lazy layout-shifts for GSAP triggers */
	function handleLazyLoad(config = {}) {
		let lazyImages = gsap.utils.toArray("img[loading='lazy']"),
			timeout = gsap
				.delayedCall(config.timeout || 1, ScrollTrigger.refresh)
				.pause(),
			lazyMode = config.lazy !== false,
			imgLoaded = lazyImages.length,
			onImgLoad = () =>
				lazyMode
					? timeout.restart(true)
					: --imgLoaded || ScrollTrigger.refresh();
		lazyImages.forEach((img, i) => {
			lazyMode || (img.loading = "eager");
			img.naturalWidth ? onImgLoad() : img.addEventListener("load", onImgLoad);
		});
	}
	// usage: you can optionally set lazy to false to change all images to load="eager". timeout is how many seconds it throttles the loading events that call ScrollTrigger.refresh()
	handleLazyLoad({lazy: true, timeout: 0.1});

	/* Navbar background state */
	function navBG(x) {
		if (x.matches) {
			let swup = document.querySelector("#swup");
			let headerbg = swup.getAttribute("data-headerbg");
			let header = document.querySelector("header");

			if (headerbg == "true") {
				header.classList.add("nav-bg");
			} else {
				header.classList.remove("nav-bg");
			}
		}
	}

	/* Hide navbar on scroll */
	function navColor(x) {
		if (x.matches) {
			(function () {
				let header = document.querySelector("header");
				let marginScroll = window.innerHeight / 2;
				let previousScroll = 0;

				window.addEventListener("resize", function () {
					marginScroll = window.innerHeight / 2;
				});

				window.addEventListener("scroll", function () {
					let currentScroll = window.scrollY;
					let headerHeight = header.clientHeight;

					if (currentScroll > marginScroll) {
						header.classList.add("scrolled");
					} else {
						header.classList.remove("scrolled");
					}

					if (currentScroll > previousScroll && currentScroll > marginScroll) {
						header.style.transform = `translateY(-${headerHeight}px)`;
					} else {
						header.style.transform = "translateY(0)";
					}
					previousScroll = currentScroll;
				});

				// Show navbar on hover or focus
				let navLinks = header.querySelectorAll("a");

				navLinks.forEach((link) => {
					link.addEventListener("focus", () => {
						header.style.transform = "translateY(0)";
					});

					link.addEventListener("blur", () => {
						if (window.scrollY > marginScroll) {
							header.style.transform = `translateY(-${header.clientHeight}px)`;
						}
					});
				});
			})();
		}
	}

	let x = window.matchMedia("(min-width: 1px)"); // Update the media query as needed
	navColor(x); // Call listener function at run time
	navBG(x);
	x.addEventListener("change", () => {
		navColor(x);
		navBG(x);
	});

	/* Slider Gallery */
	let heroScrollElement = document.querySelector(".swiper-hero-gallery");
	if (heroScrollElement) {
		let slideCount = heroScrollElement.querySelectorAll(".swiper-slide");
		let slidesNum = slideCount.length;

		let swiperHeroGallery = new Swiper(".swiper-hero-gallery", {
			modules: [Autoplay, EffectFade],
			slidesPerView: 1,
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			loopedSlides: slidesNum,
			keyboardControl: false,
			grabCursor: false,
			allowTouchMove: false,
			speed: 2000,
			effect: "fade",
			crossFade: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
		});
	}

	/* Accordion */
	let accordionRows = document.querySelectorAll(".accordion-row");
	if (accordionRows) {
		for (let i = 0; i < accordionRows.length; i++) {
			let heading = accordionRows[i].firstElementChild;

			// Ensure the heading can receive focus
			if (heading && !heading.hasAttribute("tabindex")) {
				heading.setAttribute("tabindex", "0");

				heading.addEventListener("keydown", function (e) {
					console.log("keydown");
					if (e.key === "Enter" || e.keyCode === 13) {
						this.click();
					}
				});

				heading.addEventListener("click", function () {
					let accordionRow = this.parentElement;
					let content = accordionRow.lastElementChild;

					if (accordionRow.classList.contains("active")) {
						accordionRow.classList.remove("active");
						this.classList.remove("active"); // Remove class from heading as well
						content.style.maxHeight = null;
					} else {
						accordionRow.classList.add("active");
						this.classList.add("active"); // Add class to heading as well
						content.style.maxHeight = content.scrollHeight + "px";
					}
				});
			}
		}
	}

	const projectContainer = document.querySelector(".collection__projects"); // Use querySelector to select a single element
	if (projectContainer) {
		const filterButtons = document.querySelectorAll(
			".project-filter-wrapper button"
		);
		const projects = document.querySelectorAll(".filter-item");

		filterButtons.forEach((button) => {
			button.addEventListener("click", function () {
				// Remove active class from all buttons
				filterButtons.forEach((btn) => btn.classList.remove("active"));
				// Add active class to the clicked button
				this.classList.add("active");

				// Get the category from the clicked button's text
				const category = this.innerText.trim().toLowerCase();

				// Add or remove the 'filtered' class based on the selected category
				if (category !== "alla projekt") {
					projectContainer.classList.add("filtered");
				} else {
					projectContainer.classList.remove("filtered");
				}

				// Filter the projects
				projects.forEach((project) => {
					const projectCat = project.getAttribute("data-cat").toLowerCase();
					if (category === "alla projekt" || projectCat === category) {
						showCard(project);
					} else {
						hideCard(project);
					}
				});
			});
		});

		// Fade out function
		function hideCard(element) {
			element.style.display = "none";
		}

		// Fade in function
		function showCard(element) {
			element.style.removeProperty("display");
		}
	}

	setTimeout(function () {
		const mainContentWrapper = gsap.utils.toArray(".content-wrapper");
		/* Footer / Content scaling */
		let footer_tl = gsap.timeline({
			scrollTrigger: {
				trigger: "footer",
				start: "top 80%",
				end: "bottom 20%",
				toggleActions: "play none none reset",
				//markers: true,
				onEnter: () => {
					gsap.to(mainContentWrapper, {
						scaleX: 0.95,
						translateY: "-5vh",
						duration: 0.8,
						ease: "power3.out",
						overwrite: "auto",
					});
				},
				onLeaveBack: () => {
					gsap.to(mainContentWrapper, {
						scaleX: 1,
						translateY: "0vh",
						duration: 0.8,
						ease: "power3.out",
						overwrite: "auto",
					});
				},
			},
		});

		/* Full width image */
		if (document.querySelector(".clip-wrapper")) {
			let full_w_img_section = gsap.utils.toArray(".clip-wrapper");

			/* Add media for different inset per device */
			full_w_img_section.forEach((section) => {
				let media = section.querySelector("img, video");

				gsap.to(media, {
					clipPath: "inset(0 4rem round 0.25rem)",
					ease: "none",
					scrollTrigger: {
						trigger: section,
						start: "75% bottom",
						end: "50%",
						scrub: 1,
						//markers: true
					},
				});
			});
		}

		/* Progress before/after section */
		const ProgressSection = gsap.utils.toArray(".pinned");
		if (ProgressSection) {
			ProgressSection.forEach((section) => {
				let tl = gsap.timeline({
					ease: "power1.out",
					scrollTrigger: {
						trigger: section,
						start: "95% bottom",
						end: "15% top",
						scrub: 2,
						//markers: true,
					},
				});

				tl.fromTo(
					section.querySelector(".after"),
					{xPercent: -100, x: 0},
					{xPercent: 0}
				).fromTo(
					section.querySelector(".after img"),
					{xPercent: 100, x: 0},
					{xPercent: 0},
					0
				);
			});
		}

		/* Hero offset Paralax */
		if (document.querySelector(".hero__para")) {
			let hero_media = gsap.utils.toArray(".hero__para > .content-media");
			let hero_text = gsap.utils.toArray(".hero__para > .content-text");

			let hero_tl = gsap.timeline({
				scrollTrigger: {
					trigger: ".hero__para",
					start: "top top",
					end: "bottom",
					scrub: 0,
					//markers: true,
				},
			});

			hero_media.forEach((media) => {
				hero_tl.to(
					media,
					{
						translateY: "50vh",
						ease: "none",
					},
					"<"
				); // The "<" ensures that all animations start at the same time
			});

			hero_text.forEach((text) => {
				hero_tl.to(
					text,
					{
						translateY: "25vh",
						ease: "none",
					},
					"<"
				);
			});
		}

		/* Move image within parent element */

		moveImage();

		function moveImage() {
			let section = document.querySelector(".hoverlist");

			if (section) {
				let rows = section.querySelectorAll(".hoverlist-row");
				let mediaWrapper = section.querySelector(".hover-images");
				let images = mediaWrapper.querySelectorAll("picture");

				/* Calc mouse position */
				let lastMouseX, lastMouseY;
				section.addEventListener("mousemove", function (e) {
					const rect = section.getBoundingClientRect();
					lastMouseX = e.clientX - rect.left;
					lastMouseY = e.clientY - rect.top;
					requestAnimationFrame(moveElement);
				});

				/* move Mediawrapper */
				function moveElement() {
					mediaWrapper.style.transform = `translate(${lastMouseX}px, ${lastMouseY}px)`;
				}

				rows.forEach((row) => {
					row.addEventListener("mouseenter", function () {
						const counter = this.getAttribute("data-counter");
						mediaWrapper.classList.remove("hidden");

						images.forEach((img) => {
							if (img.getAttribute("data-counter") === counter) {
								img.classList.remove("hidden");
							} else {
								img.classList.add("hidden");
							}
						});
					});
					row.addEventListener("mouseleave", function () {
						mediaWrapper.classList.add("hidden");
					});
				});
			}
		}
	}, 100); // Delay so all content gets loaded, was breaking on some pages

	/* Slider Autosize */
	var swiper = new Swiper(".sliderAuto", {
		slidesPerView: "auto",
		spaceBetween: 32,
		keyboardControl: true,
		keyboard: {
			enabled: true,
		},
		grabCursor: true,
		freeMode: false,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
		},
	});

	/* Horizontal Pin Scroll */
	/* 
	let sections = gsap.utils.toArray(".card");

    if(sections.length > 3){
    
        gsap.to(sections, {
            xPercent: -100 * (sections.length - 3),
            ease: "none",
            scrollTrigger: {
                trigger: ".horizontalWrapper",
                start: "top",
                pin: ".gsap-wrap",
                scrub: true,
                markers: true,
            }
        });
    } */

	/* SPLITTEXT STUFF */

	const targets = gsap.utils.toArray(".ani-words");
	const targets2 = gsap.utils.toArray(".ani-char");

	/*    targets2.forEach((target) => {
        mySplitText = new SplitText(target, { type: "chars"  }),
        //lines = mySplitText.lines; //an array of all the divs that wrap each character
        chars = mySplitText.chars;
 */

	// chars, words, lines

	targets.forEach((target) => {
		(mySplitText = new SplitText(target, {type: "words"})),
			//mySplitText2 = new SplitText(target, { type: "lines", linesClass: "overflow" }),
			(lines = mySplitText.words); //an array of all the divs that wrap each character

		gsap.from(lines, {
			duration: 1,
			opacity: 0,
			delay: 0.2,
			y: "0.4em",
			ease: "expo.out",
			stagger: 0.1,
			/*                 scrollTrigger: {
                trigger: target,
                start: "top 90%",
                end: "bottom 10%",
                toggleActions: "play none play none",
            } */
		});
	});

	/* Parallax stuff */

	//let paraWrapper = gsap.utils.toArray('.collection__case');
	let paraElementsOne = gsap.utils.toArray(".banan");

	if (paraElementsOne) {
		paraElementsOne.forEach((element) => {
			gsap.to(element, {
				yPercent: 20,
				ease: "none",
				scrollTrigger: {
					trigger: element,
					// start: "top bottom", // the default values
					// end: "bottom top",
					scrub: 1,
				},
			});
		});
	}

	/* Fade in */
	const fade = gsap.utils.toArray(".content-media.fade");

	fade.forEach((fade) => {
		gsap.from(fade, {
			autoAlpha: 0,
			duration: 1,
			delay: 0,
			ease: "power4.easeOut",
			scrollTrigger: {
				trigger: fade,
				start: "top 95%",
				end: "bottom 5%",
				toggleActions: "play none play none",
			},
		});
	});
}

function modalAnimation() {
	resetCursor(); // reset cursor betwene pages
}

// Credits
if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
	var args = [
		"%c Design & Developed by Milk \u00A9 https://milk.se \n",
		"padding:10px 0;background: #010101;color: #ffffff;",
	];
	window.console.log.apply(console, args);
} else if (window.console) {
	window.console.log("Design & Developed by Milk :) - https://milk.se");
}
